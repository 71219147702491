<template>
  <!-- 用户个人中心 -->
  <div class="cson-center">
    <div class="cson-container">
      <div class="center-module">
        <div class="left-menu">
          <div class="user-top">
            <div class="user-top-con">
              <router-link to="/center/imformation">
                <img
                  v-if="userInfo.avatar"
                  :src="userInfo.avatar"
                  class="logo"
                />
                <div v-else class="avatar-char">
                  {{ (userInfo.username || "").slice(0, 2) }}
                </div>
              </router-link>
              <h2>{{ userInfo.username }}</h2>
              <router-link
                v-if="
                  !userInfo.status ||
                  (userInfo.status.value !== 2 && userInfo.status.value !== 3)
                "
                to="/center/certificate"
              >
                <a class="identifi">还没实名认证？</a>
              </router-link>
              <span v-else class="true-name">已实名</span>

              <!--                            <template v-else>-->
              <!--                              <router-link to="/center/certificate">-->
              <!--                                <a class="identifi">{{ userInfo.realName }}</a>-->
              <!--                              </router-link>-->
              <!--                              <a class="clockin" @click="onClockin">-->
              <!--                                <img src="@/assets/center/center-nav-icon1.png" />上课打卡-->
              <!--                              </a>-->
              <!--                            </template>-->
            </div>
          </div>
          <div class="center-nav">
            <ul>
              <template v-for="(item, index) in leftTree">
                <li
                  :key="index"
                  :class="item.checked ? 'active' : ''"
                  :index="item.id"
                  class="el-submenu"
                  @click.stop="to(index, item)"
                >
                  <template>
                    <img
                      style="position: relative; top: -1px"
                      v-if="!item.checked"
                      :class="index == 1 || index == 2 ? 'small' : ''"
                      :src="item.icon"
                    />
                    <img
                      style="position: relative; top: -1px"
                      v-if="item.checked"
                      :class="index == 1 || index == 2 ? 'small' : ''"
                      :src="item.iconCur"
                    />
                    <span>{{ item.label }}</span>
                  </template>
                </li>
                <div
                  v-if="item.children"
                  :key="item.path"
                  :class="[item.active ? 'show' : 'hide']"
                >
                  <li
                    v-for="(childItem, cIndex) in item.children"
                    :key="cIndex + childItem.path"
                    :class="[
                      childItem.checked ? 'active' : '',
                      'el-submenu children',
                    ]"
                    @click.stop="to(cIndex, childItem, true, item)"
                  >
                    <span>{{ childItem.label }}</span>
                  </li>
                </div>
              </template>
            </ul>
          </div>
        </div>
        <div class="right-container">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["userInfo"]),
  data() {
    return {
      leftTree: [
        {
          id: "1",
          path: "/center/mylesson",
          icon: require("@/assets/center/center-nav-icon3.png"),
          iconCur: require("@/assets/center/center-nav-icon33.png"),
          label: "我的课程",
          checked: false,
        },
        {
          id: "2",
          path: "/center/order",
          icon: require("@/assets/center/center-nav-icon4.png"),
          iconCur: require("@/assets/center/center-nav-icon44.png"),
          label: "我的订单",
          checked: false,
        },
        {
          id: "0",
          path: "/center/imformation",
          icon: require("@/assets/center/center-nav-icon2.png"),
          iconCur: require("@/assets/center/center-nav-icon22.png"),
          label: "个人中心",
          checked: false,
        },
        // {
        //   id: "4",
        //   path: "/center/comments",
        //   icon: require("@/assets/center/center-nav-icon6.png"),
        //   iconCur: require("@/assets/center/center-nav-icon66.png"),
        //   label: "班课评价",
        //   checked: false,
        // },

        // {
        //   id: "6",
        //   path: "/center/subscription",
        //   icon: require("@/assets/center/center-nav-icon8.png"),
        //   iconCur: require("@/assets/center/center-nav-icon88.png"),
        //   label: "我的订阅",
        //   checked: false,
        // },
        // {
        //   id: "3",
        //   path: "/center/myComments",
        //   icon: require("@/assets/center/center-nav-icon-comment-regular.png"),
        //   iconCur: require("@/assets/center/center-nav-icon-comment-selected.png"),
        //   label: "我的评论",
        //   checked: false,
        // },
        // {
        //   id: "7",
        //   icon: require("@/assets/center/center-nav-icon-neitui.png"),
        //   iconCur: require("@/assets/center/center-nav-icon-neitui-a.png"),
        //   label: "内推中心",
        //   checked: false,
        //   active: false,
        //   children: [
        //     {
        //       id: "7-1",
        //       path: "/center/accessList",
        //       label: "我的内推",
        //       checked: false,
        //     },
        //     {
        //       id: "7-2",
        //       path: "/center/referInvite",
        //       label: "内推邀约",
        //       checked: false,
        //     },
        //     {
        //       id: "7-3",
        //       path: "/center/myResume",
        //       label: "简历信息",
        //       checked: false,
        //     },
        //   ],
        // },
        {
          id: "5",
          path: "/center/account",
          icon: require("@/assets/center/center-nav-icon7.png"),
          iconCur: require("@/assets/center/center-nav-icon77.png"),
          label: "我的账户",
          checked: false,
        },

        // {
        //   id: "8",
        //   path: "/center/mockInterview",
        //   icon: require("@/assets/center/mockInterview.png"),
        //   iconCur: require("@/assets/center/mockInterviewBlue.png"),
        //   label: "模拟面试",
        //   checked: false,
        // },
      ],
    };
  },
  watch: {
    $route(to) {
      console.log("--------", to.path);

      const index = this.leftTree.findIndex((n) => n.path === to.path);
      this.leftTree = this.leftTree.map((n) => {
        return {
          ...n,
          checked: false,
        };
      });
      if (index !== -1) {
        this.leftTree[index].checked = true;
      } else {
        const childIndex = this.leftTree[6].children.findIndex(
          (n) => n.path === this.path
        );
        this.leftTree[6].active = true;
        // this.leftTree[6].children[childIndex].checked = true;
      }
      if (to.path == "/center/csoncertificate") {
        this.leftTree[0].checked = false;
        this.leftTree[5].checked = true;
      }
    },
  },
  created() {
    if (
      !this.userInfo.status ||
      (this.userInfo.status.value !== 2 && this.userInfo.status.value !== 3)
    ) {
      this.leftTree.push({
        id: "7",
        path: "/center/certificate",
        icon: require("@/assets/center/shiming.png"),
        iconCur: require("@/assets/center/shiming2.png"),
        label: "实名认证",
        checked: false,
      });
      console.log("0");
    } else {
      console.log("1");
    }
  },
  mounted() {
    this.path = this.$route.path;
    if (
      this.$route.path == "/center/csoncertificate" ||
      this.$route.path == "/center/csoncertificate/"
    )
      this.path = "/center/certificate";
    const index = this.leftTree.findIndex((n) => n.path === this.path);
    this.leftTree = this.leftTree.map((n) => {
      return {
        ...n,
        checked: false,
      };
    });
    if (index !== -1) {
      this.leftTree[index].checked = true;
    } else {
      const childIndex = this.leftTree[6].children.findIndex(
        (n) => n.path === this.path
      );
      this.leftTree[6].active = true;
      this.leftTree[6].children[childIndex].checked = true;
    }
    console.log(this.userInfo);
  },
  methods: {
    onClockin() {
      this.$message("此功能暂未开放");
    },
    to(index, item, ischildren, parent) {
      if (item.children) {
        item.active = !item.active;
        return;
      }
      if (ischildren) {
        parent.children.forEach((element, i) => {
          if (i == index) {
            console.log(i, index, element);
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
        this.leftTree.forEach((element, i) => {
          element.checked = false;
        });
        this.$router.push(item.path);
        this.$forceUpdate();
      } else {
        this.leftTree.forEach((element, i) => {
          if (element.children) {
            element.children.map((item) => {
              item.checked = false;
            });
          }
          if (i == index) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
        this.$router.push(item.path);
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/center.css";

.true-name {
  color: #0a7aff;
  font-size: 12px;
  text-decoration: underline;
  display: block;
  margin: 10px auto 20px auto;
  text-align: center;
}
.el-submenu.children {
  padding-left: 28px;
}
.hide {
  display: none;
}
.show {
  display: block;
}
</style>
