var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cson-center"},[_c('div',{staticClass:"cson-container"},[_c('div',{staticClass:"center-module"},[_c('div',{staticClass:"left-menu"},[_c('div',{staticClass:"user-top"},[_c('div',{staticClass:"user-top-con"},[_c('router-link',{attrs:{"to":"/center/imformation"}},[(_vm.userInfo.avatar)?_c('img',{staticClass:"logo",attrs:{"src":_vm.userInfo.avatar}}):_c('div',{staticClass:"avatar-char"},[_vm._v(" "+_vm._s((_vm.userInfo.username || "").slice(0, 2))+" ")])]),_c('h2',[_vm._v(_vm._s(_vm.userInfo.username))]),(
                !_vm.userInfo.status ||
                (_vm.userInfo.status.value !== 2 && _vm.userInfo.status.value !== 3)
              )?_c('router-link',{attrs:{"to":"/center/certificate"}},[_c('a',{staticClass:"identifi"},[_vm._v("还没实名认证？")])]):_c('span',{staticClass:"true-name"},[_vm._v("已实名")])],1)]),_c('div',{staticClass:"center-nav"},[_c('ul',[_vm._l((_vm.leftTree),function(item,index){return [_c('li',{key:index,staticClass:"el-submenu",class:item.checked ? 'active' : '',attrs:{"index":item.id},on:{"click":function($event){$event.stopPropagation();return _vm.to(index, item)}}},[[(!item.checked)?_c('img',{class:index == 1 || index == 2 ? 'small' : '',staticStyle:{"position":"relative","top":"-1px"},attrs:{"src":item.icon}}):_vm._e(),(item.checked)?_c('img',{class:index == 1 || index == 2 ? 'small' : '',staticStyle:{"position":"relative","top":"-1px"},attrs:{"src":item.iconCur}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.label))])]],2),(item.children)?_c('div',{key:item.path,class:[item.active ? 'show' : 'hide']},_vm._l((item.children),function(childItem,cIndex){return _c('li',{key:cIndex + childItem.path,class:[
                    childItem.checked ? 'active' : '',
                    'el-submenu children',
                  ],on:{"click":function($event){$event.stopPropagation();return _vm.to(cIndex, childItem, true, item)}}},[_c('span',[_vm._v(_vm._s(childItem.label))])])}),0):_vm._e()]})],2)])]),_c('div',{staticClass:"right-container"},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }